

























































































import { Component, Vue, Emit } from 'vue-property-decorator'
import {
  StudentController,
  GradeController,
  TaskGradeController,
  DropDownController,
} from '@/services/request.service'
import { DropdownButton } from 'ant-design-vue/types/dropdown/dropdown-button'
import { round } from 'lodash'
import FlexTooltip from '@/components/FlexTooltip.vue'

@Component({
  components: {
    FlexTooltip,
  },
})
export default class Grading extends Vue {
  private filter: any = {
    schoolYearId: undefined,
  }
  private schoolYears: Array<any> = []
  private data: Array<any> = []
  private semesters: Array<any> = []
  private maxTaskNum = 0
  private round = round
  private windowH = 0

  private get columns(): Array<any> {
    let tasks = [] as any
    for (let i = 0; i < this.maxTaskNum; i++) {
      tasks.push({
        key: 'task-' + i,
        colSpan: i === 0 ? this.maxTaskNum : 0,
        align: 'left',
        width: 120,
        title: this.$t('router.assignment'),
        customCell: record => {
          return {
            style: {
              padding: '0',
            },
          }
        },
        scopedSlots: {
          customRender: 'task-' + i,
        },
      })
    }
    return [
      {
        key: 'subject',
        title: this.$t('common.subject'),
        width: 200,
        dataIndex: 'subject',
        scopedSlots: {
          customRender: 'subject',
        },
      },
      {
        key: 'courseClass',
        title: this.$t('common.subjectClass'),
        width: 200,
        dataIndex: 'courseName',
      },
      {
        key: 'finalScore',
        title: this.$t('student.finalScore'),
        align: 'center',
        width: 120,
        scopedSlots: {
          customRender: 'finalScore',
        },
      },
      ...this.semesters.map((semester, index) => {
        return {
          key: semester.gradePeriodId,
          title: semester.abbreviation + ' Percentage',
          align: 'center',
          width: 120,
          scopedSlots: { customRender: 'semester-' + semester.gradePeriodId },
        }
      }),
      ...tasks,
      {
        key: 'flex',
      },
    ]
  }

  private get locale(): any {
    return this.$store.state.locale
  }

  private created(): void {
    this.getSchoolYears()
  }

  private getSchoolYears(): void {
    DropDownController.getSchoolYearList()
      .then(res => {
        this.schoolYears = res.data
        this.$set(this.filter, 'schoolYearId', res.data[0].key)
        this.getData()
      })
      .catch(err => {
        console.error(err)
      })
  }

  private getData(): void {
    TaskGradeController.getStudentGradeBook(this.filter.schoolYearId)
      .then((res: any) => {
        this.data = res.data.gradeBookItems
        this.semesters = res.data.gradePeriods
        let maxTaskNum = 0
        res.data.gradeBookItems.forEach(item => {
          maxTaskNum = maxTaskNum < item.taskScores.length ? item.taskScores.length : maxTaskNum
        })
        this.maxTaskNum = maxTaskNum
      })
      .catch(err => {
        console.error(err)
      })
  }

  private mounted(): any {
    setTimeout(() => {
      // console.log(this)
      this.windowH = this.$el.parentElement?.getBoundingClientRect().height || 400
    }, 0)
  }
}
